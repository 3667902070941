import { useWindowSize } from './data/hooks';

function App() {
  const { width, height } = useWindowSize();

  return (
    <main style={{
      height,
      width,
    }}
    className="d-flex p-3 border-box flex-column justify-content-center align-items-center text-align-center"
    >
      <h1 className="d1">Lyceum Games</h1>
      <h2 className="mb-5">Purveyors of fine half-finished indie games since 2002.</h2>
    </main>
  );
}

export default App;
